<template>
  <div class="showCircleW">
    <div class="short-video-player" style="height: 38.85417vw">
      <swiper-video
        :videoList="videoList"
        :innerWidth="w"
        :innerHeight="h"
        @activeIndex="activeIndex($event)"
      >
      </swiper-video>
    </div>
    <div class="showCircleListBox">
      <div class="showCircleListBoxName">
        <img src="../../assets/zan.png" alt="" />
        <div class="name">秀圈推荐</div>
      </div>
      <div style="flex: 1; display: flex; align-items: center">
        <div class="showCircleList">
          <el-carousel
            ref="cardShow"
            style="flex: 1"
            :loop="false"
            :autoplay="false"
            indicator-position="none"
            arrow="never"
            :initial-index="showCircleIndex - 1"
            @change="changeShowCircle"
            trigger="click"
          >
            <el-carousel-item v-for="(list, i) in videoArr" :key="i">
              <div
                class="showCircleItem"
                v-for="(item, index) in list"
                :key="index"
              >
                <div
                  class="videoAndImg"
                  @mouseover="
                    videoPlayback(index, list[index].video_address, i)
                  "
                  @mouseout="videoStopped(index, list[index].video_address, i)"
                  @click="toPlayer(item)"
                >
                  <img
                    class="showCircleItemImg"
                    :src="list[index].video_address?(list[index].video_address+'?vframe/jpg/offset/0'):list[index].imgs[0]"
                    alt=""
                    ref="img"
                  />
                  <!-- contextmenu(禁止右键) muted(解决谷歌浏览器不能自动播放)-->
                  <div class="video_box">
                    <video
                      id="video"
                      ref="video"
                      width="100%"
                      height="100%"
                      @contextmenu.prevent
                      controls
                      controlslist="nodownload nofullscreen noremote footbar"
                      disablePictureInPicture
                      muted="muted"
                      style="display: none; object-fit: cover"
                    >
                      <!-- :poster="list[index].thumb"> -->
                      <source
                        type="audio/mp4"
                        :src="list[index].video_address"
                      />
                    </video>
                  </div>
                  <span class="video_time">{{ videoTime }}</span>
                </div>

                <div class="showCircleItemInfo">
                  <div class="showCircleItemInfoTitle ellipsis">
                    {{ list[index].title }}
                  </div>

                  <div class="touxiangAndName">
                    <img
                      class="touxiangImg"
                      :src="list[index].user_info.avatar"
                      alt=""
                    />
                    <div class="name">
                      {{ list[index].user_info.nick_name }}
                    </div>
                  </div>
                  <div class="transverse"></div>
                  <div class="showCircleItemSet">
                    <div class="showCircleItemSetItem">
                      <img
                        class="showCircleItemSetIcon"
                        src="../../assets/dianzanIcon.png"
                        alt=""
                      />
                      <span>{{ list[index].like }}</span>
                    </div>
                    <div class="showCircleItemSetItem">
                      <img
                        class="showCircleItemSetIcon"
                        src="../../assets/pinglunIcon.png"
                        alt=""
                      />
                      <span>{{ list[index].comment_num }}</span>
                    </div>
                    <div class="showCircleItemSetItem">
                      <img
                        class="showCircleItemSetIcon"
                        src="../../assets/zhuanfaIcon.png"
                        alt=""
                      />
                      <span>{{ list[index].retweet_num }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="switchPage">
          <div
            style="margin-bottom: 1.04167vw"
            class="previous switchPageBtn"
            @click="arrowClick('left')"
          >
            <img
              v-show="showCircleIndex == 1"
              class="switchPageBtnImg"
              src="../../assets/previous.png"
              alt=""
            />
            <img
              style="transform: rotate(180deg)"
              v-show="showCircleIndex != 1"
              class="switchPageBtnImg"
              src="../../assets/next.png"
              alt=""
            />
          </div>
          <div class="next switchPageBtn" @click="arrowClick('right')">
            <img
              style="transform: rotate(180deg)"
              v-show="this.showCircleIndex * 8 >= this.arr.length"
              class="switchPageBtnImg"
              src="../../assets/previous.png"
              alt=""
            />
            <img
              v-show="this.showCircleIndex * 8 < this.arr.length"
              class="switchPageBtnImg"
              src="../../assets/next.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwiperVideo from "@/components/swiper/index.vue";
import { getVideoList } from "../../utils/api.js";

export default {
  components: {
    SwiperVideo,
  },
  data() {
    return {
      videoList: [],
      w: "100%",
      h: "100%",
      videoTime: "",
      timer: "",
      videoArr: [],
      page: {
        page_limit: 3,
        page_num: 1,
      },
      pages: {
        page_limit: 16,
        page_num: 1,
      },
      k: 0,
      showCircleIndex: 1,
      count: 0,
      arr: [],
    };
  },
  mounted() {
    // this.byEvents()
    this.getVideoLists();
    this.getShowCircleList();

    console.log(window.innerWidth);
  },
  // created() {
  //     // const videoData = JSON.parse(sessionStorage.getItem("rowData"));
  //     // this.videoList = videoData
  //     // if (this.$route.query.key) {
  //     //     this.isHeight = true
  //     //     this.h = window.innerHeight + 'px'
  //     //     this.initialSlide = this.$route.query.key
  //     // }
  // },
  methods: {
    activeIndex(e) {
      this.k = e;
      // console.log(this.k);
      if (this.k == this.videoList.length - 2) {
        this.page.page_num++;
        this.getVideoLists();
        //  ;
      }
    },
    //  秀圈
    getVideoLists() {
      // console.log(this.$API.);
      getVideoList({
        full_fields: [
          "user_info",
          "is_like",
          "goods_info",
          "type_info",
          "is_thumb",
        ],
        page: this.page,
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          // this.videoList = res.data
          let arr = res.data;
          for (let i = 0; i < arr.length; i++) {
            arr[i].page_num = this.page.page_num
            this.videoList.push(arr[i]);
          }
        }
        // this.videoArr = res.data
      });
    },

    //  秀圈
    getShowCircleList() {
      // console.log(this.$API.);
      getVideoList({
        full_fields: [
          "user_info",
          "is_like",
          "goods_info",
          "type_info",
          "is_thumb",
        ],
        page: this.pages,
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          let arr = this.deepClone(res.data);
          console.log(arr);
          res.data.map(item => {
            item.page_num = this.pages.page_num
          })
          this.count = res.page.data_count;
          // let videoArr = res.data
          if (this.pages.page_num != 1) {
            this.arr = this.arr.concat(res.data);
          } else {
            this.arr = res.data;
          }

          let newDataList = [];
          let current = 0;
          if (this.arr && this.arr.length > 0) {
            for (let i = 0; i <= this.arr.length - 1; i++) {
              if (i % 8 !== 0 || i === 0) {
                //数据处理成几张展示
                if (!newDataList[current]) {
                  newDataList.push([this.arr[i]]);
                } else {
                  newDataList[current].push(this.arr[i]);
                }
              } else {
                current++;
                newDataList.push([this.arr[i]]);
              }
            }
          }
          this.videoArr = [...newDataList];
        }
        // this.videoArr = res.data
      });
    },

    changeShowCircle(e) {
      this.showCircleIndex = e + 1;
      if (this.showCircleIndex * 4 == this.arr.length - 4) {
        this.pages.page_num++;
        this.getShowCircleList();
      }
    },
    //深拷贝
    deepClone(obj) {
      let o = {};
      for (let key in obj) {
        o[key] = obj[key];
      }
      return o;
    },

    videoPlayback(index, video_address, i) {
      // i 是父下标
      // index 是子下标

      const video = this.$refs.video[i * 8 + index];
      if (video_address) {
        // 显示播放器
        video.style.display = "block";
        // 隐藏图片
        this.$refs.img[i * 8 + index].style.display = "none";
        // 定时器
        this.timer = setTimeout(() => {
          // 播放视频
          video.play();
          // 给视频标签添加缓存播放---video标签属性。
          video.setAttribute("autoplay", "autoplay");
          // 给视频标签添加循环播放---video标签属性。
          video.setAttribute("loop", "loop");
          // 初始播放音量
          video.volume = 0;
        }, 200);
        // 视频当前的播放时间(进度)
        // setInterval(() => {
        //     console.log(video.currentTime);
        // }, 0);
        // 获取视频时长
        // let minutes = parseInt(video.duration / 60); // 分
        // let seconds = parseInt(video.duration % 60); // 秒
        // minutes >= 10 ? minutes = minutes : minutes = "0" + minutes
        // seconds >= 10 ? seconds = seconds : seconds = "0" + seconds
        // this.videoTime = minutes + ":" + seconds
        // console.log(this.videoTime);
      }
    },
    videoStopped(index, video_address, i) {
      const video = this.$refs.video[i * 8 + index];
      if (video_address) {
        // 清除定时器
        clearTimeout(this.timer);
        //停止播放
        video.pause();
        // 重置播放时间
        video.currentTime = 0;
        // 隐藏播放器
        video.style.display = "none";
        // 隐藏图片
        this.$refs.img[i * 8 + index].style.display = "block";
      }
    },
    toPlayer(item) {
    //  let arr = this.arr;
    //   arr.forEach((item, index) => {
    //     if (id === item.id) {
    //       this.$router.push({
    //         path: "Player",
    //         query: { id: item.id, key: index },
    //       });
    //     }
    //   });

    //   sessionStorage.setItem("rowData", JSON.stringify(arr));
    this.$router.push({
        path: "Player",
        query: { uuid: item.uuid, page_num: item.page_num, totalPage: this.totalPage },
      });
    },

    arrowClick(val) {
      console.log(val);
      if (val === "right") {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
.showCircleListBox {
  width: 1240px;
  margin: auto;
  padding: 120px 70px 102px 133px;

  .showCircleListBoxName {
    margin-bottom: 50px;
    display: flex;
    align-content: center;

    img {
      width: 30px;
      height: 32px;
      margin-right: 20px;
    }

    .name {
      font-size: 28px;
      font-weight: 500;
      color: #666666;
    }
  }

  .showCircleList {
    flex: 1;

    .el-carousel {
      /deep/ .el-carousel__container {
        height: 794px;

        .el-carousel__item {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    .showCircleItem {
      margin-right: 50px;
      margin-bottom: 60px;
      width: 241px;
      height: 367px;
      background: rgba(231, 202, 198, 0.3);
      border-radius: 8px;

      .videoAndImg {
        width: 100%;
        height: 186px;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;

        img,
        .video_box {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        img {
          // 解决图片底部默认空白缝隙问题
          display: block;
        }
      }

      .showCircleItemImg {
        width: 241px;
        height: 186px;
        border-radius: 8px 8px 0 0;
      }

      .showCircleItemInfo {
        padding: 10px 15px 18px;
        height: 181px;

        .showCircleItemInfoTitle {
          font-size: 14px;
          color: #666666;
          margin-bottom: 10px;
        }

        .touxiangAndName {
          display: flex;
          align-items: center;
          margin-bottom: 18px;

          .touxiangImg {
            width: 15px;
            height: 15px;
            margin-right: 6px;
          }

          .name {
            font-size: 13px;
            font-weight: 400;
            color: #999999;
          }
        }

        .transverse {
          margin-bottom: 21px;
          height: 1px;
          opacity: 0.8;
          border: 1px solid #e6e6e6;
        }

        .showCircleItemSet {
          display: flex;
          justify-content: space-between;
          padding: 0 25px;

          .showCircleItemSetItem {
            display: flex;
            flex-direction: column;
            align-items: center;

            .showCircleItemSetIcon {
              margin-bottom: 9px;
              width: 26px;
              height: 22px;
            }

            span {
              font-size: 13px;
              color: #666666;
            }
          }
        }
      }
    }
  }

  .switchPage {
    .switchPageBtn {
      display: flex;
      width: 71px;
      height: 71px;
      cursor: pointer;

      .switchPageBtnImg {
        width: 71px;
        height: 71px;
      }
    }
  }
}

// 播放器样式
video::-webkit-media-controls-timeline {
  // 进度条
  width: 100%;
  padding: 0;
}

//观看的当前时间
video::-webkit-media-controls-current-time-display {
  display: none !important;
}

//剩余时间
video::-webkit-media-controls-time-remaining-display {
  display: none !important;
}

//音量按钮
video::-webkit-media-controls-mute-button {
  display: none !important;
}

video::-webkit-media-controls-toggle-closed-captions-button {
  display: none !important;
}

//音量的控制条
video::-webkit-media-controls-volume-slider {
  display: none !important;
}

// 全屏按钮
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

//播放按钮
video::-webkit-media-controls-play-button {
  display: none !important;
}

// 播放控件
video::-webkit-media-controls {
  // 进度条
  opacity: 1;
  // 让鼠标指针一直显示
  cursor: pointer !important;
}
</style>