<template>
  <div class="short-video-player">
    <swiper-video :videoList="videoList" :innerWidth="w" :innerHeight="h" :isHeight="isHeight"
      :initialSlide="initialSlide" :commentsList="commentsList" @activeIndex="activeIndex">
    </swiper-video>

    <!-- 关闭按钮 -->
    <div class="to-back" @click="$router.back(-1)">
      <i class="el-icon-close"></i>
    </div>
  </div>
</template>

<script>
import SwiperVideo from "../../components/swiper/index.vue";
import { getVideoList, shopVideosRead } from "../../utils/api.js";

export default {
  components: {
    SwiperVideo,
  },
  data() {
    return {
      videoList: [],
      w: "100%",
      h: "100%",
      isHeight: false,
      initialSlide: 0,
      timer: null,
      commentsList: [],
      page: {
        page_num: 1, //页数
        page_limit: 10, //条数
      },
      uuid: '',
      keyword:''
    };
  },
  created() {
    // const videoData = JSON.parse(sessionStorage.getItem("rowData"));
    // this.videoList = videoData;
    if (this.$route.query.uuid) {
      this.uuid = this.$route.query.uuid
      this.page.page_num = (this.$route.query.page_num * 1) + 1
      this.isHeight = true;
      this.h = window.innerHeight + "px";
      this.initialSlide = 0;
      this.getVideoRead()
    }
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        that.h = window.innerHeight + "px";
      })();
    };
  },
  watch: {
    h(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.h);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //视频详情
    getVideoRead() {
      let data = {
        condition: {
          uuid: this.uuid
        },

        full_fields: ['user_info', 'is_like', 'goods_info', 'type_info', 'is_thumb',
          'shop_is_live',
          'is_low', 'shop_info'
        ]
      }

      shopVideosRead(data).then(res => {
        console.log(res);
        if (res.code == 1) {
          let datas = res.data
          // let dataList = this.dataList
          // dataList.push(datas)
          // this.dataList = dataList
          // this.get() //这一步，加载视频数据
          this.videoList.unshift(datas)
          if (this.page.page_num == this.totalPage) {
            this.page.page_num = 1
          }
          this.getVideoLists()
        }
      })
    },


    activeIndex(e) {
      this.initialSlide = e;
      if (this.initialSlide == this.videoList.length - 2) {
        this.page.page_num++;
        this.page.page_limit == this.videoList.length;
        this.getVideoLists();
        // console.log(this.videoList);
      }
    },

    //  秀圈
    getVideoLists() {
      console.log(123);
      // console.log(this.$API.);
      this.keyword = this.$route.query.keyword;
      let data = {
        full_fields: [
          "user_info",
          "is_like",
          "goods_info",
          "type_info",
          "is_thumb",
        ],
        map: {
          desc: this.keyword ? this.keyword : null
        },
        page: this.page,
      }
      if (!this.keyword) {
        delete data.map.desc
      }

      console.log(data,'data');
      getVideoList(data).then((res) => {
        console.log(res);
        if (res.code == 1) {
          // this.videoList = res.data
          // let arr = res.data;
          for (let i = 0; i < res.data.length; i++) {
            if (this.uuid != res.data[i].uuid) {
              this.videoList.push(res.data[i]);
            }
          }
          if (this.page.page_num == this.totalPage) {
            this.page.page_num = 0
          }
        }
        // this.videoArr = res.data
      });
    },
  },
};
</script>

<style lang="less" scoped>
// 关闭按钮
.to-back {
  cursor: pointer;
  width: 64px;
  height: 64px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 9;
  text-align: center;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    color: #dadada;
    font-size: 30px;
    font-weight: bold;
    line-height: 64px;
  }

  &:hover {
    opacity: 1;
  }
}
</style>